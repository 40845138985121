<template>
<div class="common">

    <div class="commonTop flex flex-ai-c flex-jc-sb">
		<div class="name flex flex-ai-c">{{$root.userInfo.ext_company_name}}{{$config.websiteTitle}}</div>
		<div class="" style="margin-right:auto;margin-left:20px">
			{{$root.userInfo.ext_time.date}} {{$root.userInfo.ext_time.week_no}}
		</div>
		<div :style="{color:$root.userInfo.ext_company.ext_deadline_left_days<30?'Wheat':''}" style="margin-left:auto;margin-right:50px;cursor:pointer;" @click="goMana" v-if="Number($root.userInfo.admin_is)==1">
			<i class="el-icon-warning"></i>
			到期时间：{{$root.userInfo.ext_company_deadline_date}}（剩余{{$root.userInfo.ext_company.ext_deadline_left_days}}天）
        </div>
        <div class="userbox flex flex-ai-c flex-jc-sb">
			<el-dropdown trigger="hover" class="msg" style="cursor: pointer;margin-right:50px">
                <span class="el-dropdown-link">
                    <i class="el-icon-question"></i>
                    <span style="margin-left:5px">客户服务</span>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
					<div class="flex flex-column flex-ai-c flex-jc-sa">
						<p style="padding:0 10px;text-align:center;line-height:1.5;color:#333;font-family:微软雅黑;">
							客服电话：{{$root.userInfo.service.mobile}}<br>
							或者扫描下方二维码直达客服微信：
						</p>
						<img :src="this.domain+$root.userInfo.service.qrcode" style="width: 200px;padding: 10px;box-sizing: border-box;">
					</div>
				</el-dropdown-menu>
            </el-dropdown>
            <div class="user">
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link flex flex-ai-c">
                        {{$root.userInfo.realname?$root.userInfo.realname:'管理员'}}<i class="el-icon-arrow-down" style="margin-left:5px"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item icon="el-icon-monitor" @click.native="goover()">进入前台</el-dropdown-item>
                        <!-- <el-dropdown-item  icon="el-icon-unlock" @click.native="repwd()">修改密码</el-dropdown-item> -->
                        <el-dropdown-item icon="el-icon-s-unfold" @click.native="logOut()">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>

    <div class="commonLeft" :style="{'width':isCollapse?'64px':'170px'}">
        <div class="nav">
            <el-aside>
                <!-- <div class="headImg">
                    <img :style="{'width':isCollapse?'42px':'60px'}" src="../../assets/avatars.jpg" alt="">
                    <p v-show="!isCollapse">{{$root.userInfo.name}}</p>
                </div> -->
                <el-menu background-color="#191A23" text-color="#A2B4BA" :default-active="$route.path" active-text-color="#fff" :router="true" :collapse="isCollapse" style="height:calc( 100% - 145px )">
                    <template v-for="nav in navList">
                        <el-menu-item :index="nav.path" :key="nav.name" v-if="!nav.sub">
                            <i :class="nav.icon"></i>
                            <span slot="title">
                                {{nav.name}}
                            </span>
                        </el-menu-item>
                        <el-submenu :index="nav.path" :key="nav.name" v-if="nav.sub">
                            <template slot="title">
                                <i :class="nav.icon" v-if="nav.icon"></i>
                                <span> {{nav.name}} </span>
                            </template>
                            <template v-for="item in nav.sub">
                                <el-menu-item :index="item.path" :key="item.name" v-if="!item.sub">
                                    <i :class="item.icon" v-if="item.icon"></i>
                                    <span style="font-size:13px;">{{item.name}}</span>
                                </el-menu-item>
                            </template>
                        </el-submenu>
                    </template>
                    <p style="height:50px;background:#191A23"></p>
                </el-menu>

                <div class="menuBtnbox" :style="{'width':isCollapse?'64px':'170px'}">
                    <i class="el-icon-s-operation menuBtn" @click="handleCollapse()"></i>
                </div>
            </el-aside>
        </div>
    </div>
    <div class="commonRight" :style="{'left':isCollapse?'64px':'170px'}">
        <router-view :isCollapse="isCollapse" />
    </div>

    <el-dialog title="修改密码" :visible.sync="repwdVisible" :modal-append-to-body="false" :close-on-click-modal="false" width="380px" center>
        <el-form ref="formAuth" :model="pwdForm" :rules="formRules" :label-width="formLabelWidth">
            <el-form-item label="原密码" prop="old_password">
                <el-input size="medium" v-model="pwdForm.old_password"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="new_password">
                <el-input size="medium" show-password v-model="pwdForm.new_password"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="small" type="primary" @click="repwdEnter" style="width:100%">确 定</el-button>
        </div>
    </el-dialog>
</div>
</template>

<script>
import axios from 'axios';
// import driverStep from "@/plugins/driver"
export default {
    data: function() {
        var oldPassword = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('请输入原密码'));
            } else {
                callback();
            }
        };
        var newPassword = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请输入新密码'));
            } else {
                if (this.pwdForm.newPassword !== '') {
                    this.$refs.formAuth.validateField('verifyPassword');
                }
                callback();
            }
        };
        var verifyPassword = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.pwdForm.newPassword) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            pwdForm: {
                id:''
            },
            count: '',
            formRules: {
                old_password: [{
                    validator: oldPassword,
                    trigger: 'blur'
                }],
                new_password: [{
                    validator: newPassword,
                    trigger: 'blur'
                }],
                verifyPassword: [{
                    validator: verifyPassword,
                    trigger: 'blur'
                }],
            },
            formLabelWidth: '80px', //弹出form label 宽
            repwdVisible: false,
            defaultActive: this.$route.path,
            isCollapse: false,
            userInfo: {},
            navList: [{
                path: '/organization',
                name: '组织信息',
                icon: 'el-icon-menu',
            },{
                path: '/relationship',
                name: '组织架构',
                icon: 'el-icon-menu',
            },{
                path: '/common',
                name: '系统设置',
                icon: 'el-icon-menu',
            },{
                path: '/department',
                name: '部门岗位',
                icon: 'el-icon-menu',
            },{
                path: '/classify',
                name: '岗位职责',
                icon: 'el-icon-menu',
            },{
				path: '/payment',
                name: '缴费管理',
                icon: 'el-icon-menu',
			}
			// ,{
            //     path: '/admin',
            //     name: '工作类别',
            //     icon: 'el-icon-menu',
            // }
			// ,{
            //     path: '/admin',
            //     name: '管理员信息',
            //     icon: 'el-icon-menu',
            // },{
            //     path: '/stopUser',
            //     name: '停用人员',
            //     icon: 'el-icon-menu',
            // },{
            //     path: '/statement',
            //     name: '系统说明',
            //     icon: 'el-icon-menu',
            // }
		],
        }
    },
    mounted() {
        this.init();
        this.pwdForm.id=this.$root.userInfo.id;

		// 在mounted生命周期或方法中执行下述代码
		// setTimeout(()=>{
		// 	this.$driver.defineSteps(driverStep)
		// 	this.$driver.start()
		// },1000)
    },
    //导航守卫
    beforeRouteEnter(to, from, next) {
		let _token = window.sessionStorage.getItem('_token');
        axios.post(window.domain + '/Login/state', {
            id: sessionStorage.getItem('userId')
        }, {
			headers: {
				'content-type': 'application/json',
				'X-CSRF-TOKEN': _token
			}
		}).then(re => {
            next(vm => {
                // 通过 `vm` 访问组件实例
                if (re.data.code == 200) {
                    vm.$root.userInfo = re.data.data;
                }
            })
        })
    },
    methods: {
        init: function() {

        },
		gooverview(){
			let _token = window.sessionStorage.getItem('_token');
			window.open(this.domain+'/workOverview/dist/index.html?token='+_token);
		},
		goMana(){
			this.$router.push('/payment');
			// var url = '/admin.company/rootLogout';
			// this.http.post(url).then(() => {
			// 	this.$router.push('/headquartersMerchant');
			// });
		},
        goindex: function() {
            this.$router.push('/overview');
        },
        //nav跳转
        navTo: function(path) {
            this.$router.push(path);
        },
        //
        handleCollapse: function() {
            this.isCollapse = !this.isCollapse;
        },
        repwd: function() {
            this.repwdVisible = true
        },
        logOut: function() {
            this.http.post('/login/logout').then(() => {
                this.$router.push('/');
                sessionStorage.clear();
            })
        },
        goover(){
            sessionStorage.removeItem('typeSelf');
            sessionStorage.removeItem('inputweekSelf');
            sessionStorage.removeItem('inputmonthSelf');
            this.$router.push('/overview')
        },
        repwdEnter: function() {
            this.$refs['formAuth'].validate((valid) => {
                if (valid) {
                    this.http.post('/admin.admin/changePassword', {
                        old_password:this.$md5(this.pwdForm.old_password),
                        new_password:this.$md5(this.pwdForm.new_password),
                    }).then(() => {
                        this.repwdVisible = false;
                        this.$message({
                            message: '修改成功!',
                            type: 'success'
                        });
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })
        },
    },
    // beforeUpdate: function() {
    //     if (this.$root.userInfo != '') this.userInfo = this.$root.userInfo;
    // },
}
</script>
